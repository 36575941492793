import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

interface ILabelProps {
  htmlFor?: string;
  label?: string;
  className?: string;
}

const BaseLabel = styled.label(
  sx2CssThemeFn({
    display: 'flex',
    margin: 0,
    alignItems: 'center',
    fontSize: '14px',
    color: 'darkGrey',
    mb: 1,
    span: {
      ml: 1,
    },
  })
);

const SearchControlFieldLabel: React.FC<
  React.PropsWithChildren<ILabelProps>
> = ({ label, htmlFor, children, className }) => {
  return (
    <BaseLabel htmlFor={htmlFor} className={`field-label ${className}`}>
      {label}
      {children}
    </BaseLabel>
  );
};

export default SearchControlFieldLabel;
