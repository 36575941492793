import { useEffect, useMemo } from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import { TravelType } from '@hotelplan/graphql.types';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { IFiltersSearchState } from 'components/domain/filters/Filters.types';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { useGetTotalSearchResultsQuery } from 'graphql/home/GetTotalSearchResults.generated';
import { mapHomeFormValueToSearchState } from './Home.mappers';
import { THomeFormState } from './Home.types';

interface ISearchTotalResults {
  totalResults: number;
  totalResultsLoading: boolean;
  showNoResultModal: boolean;
  switchTravelType(): void;
  altTravelType: TravelType;
}

function getVariablesByTravelType(
  searchControl: ISearchControlState,
  filters: Pick<IFiltersSearchState, 'departureAirports'>,
  travelType?: TravelType
) {
  return {
    criteria: {
      searchControl: mapSearchControlToCriteria({
        ...searchControl,
        travelType: travelType || searchControl.travelType,
      }),
      filters:
        searchControl.travelType === TravelType.Hotel
          ? null
          : mapSrlFilterValuesToSrlFilterCriteriaInput(filters),
    },
  };
}

export function useSearchTotalResults(): ISearchTotalResults {
  const { values, onChange } = useFormContext<THomeFormState>();
  const { searchControl, filters } = mapHomeFormValueToSearchState(values);

  const [showNoResultModal, openNoResultsModal, closeNoResultsModal] =
    useToggleState(false);

  const altTravelType =
    searchControl.travelType === TravelType.Package
      ? TravelType.Hotel
      : TravelType.Package;

  const alternativeVariables = getVariablesByTravelType(
    searchControl,
    filters,
    altTravelType
  );

  const currentFormVariables = getVariablesByTravelType(searchControl, filters);

  const { data: currentResults, loading: currentResultsLoading } =
    useGetTotalSearchResultsQuery({
      variables: currentFormVariables,
    });

  const { data: alternativeResults, loading: alternativeResultsLoading } =
    useGetTotalSearchResultsQuery({
      variables: alternativeVariables,
      skip:
        currentResultsLoading ||
        currentResults?.home.totalProductCount.totalCount > 0,
    });

  useEffect(() => {
    if (currentResults && alternativeResults) {
      if (
        currentResults.home.totalProductCount.totalCount === 0 &&
        alternativeResults.home.totalProductCount.totalCount > 0
      )
        openNoResultsModal();
    }
  }, [alternativeResults, currentResults]);

  function switchToAltTravelType() {
    onChange({
      ...values,
      travelType: altTravelType,
    });
    closeNoResultsModal();
  }

  return useMemo(
    () => ({
      totalResults: currentResults?.home.totalProductCount.totalCount || 0,
      totalResultsLoading: currentResultsLoading || alternativeResultsLoading,
      showNoResultModal,
      altTravelType,
      switchTravelType: switchToAltTravelType,
    }),
    [
      showNoResultModal,
      currentResults?.home.totalProductCount.totalCount,
      alternativeResults?.home.totalProductCount.totalCount,
      alternativeResultsLoading,
      currentResultsLoading,
    ]
  );
}
