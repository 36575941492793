import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  ESearchControlVariants,
  useSearchControlVariants,
} from 'components/domain/search-control/hooks/useSearchControlVariants';
import { ISearchControlFormLayoutProps } from './SearchControlFormLayout.types';
import {
  SearchControlFields,
  SearchControlFieldsFooter,
  SearchControlFieldsInputs,
  SearchControlFieldsWrapper,
  SearchControlFormWrapper,
  SearchControlTitle,
} from './SearchControlFormLayoutSeparateBlock.styles';

export default function SearchControlFormLayoutSeparateBlock({
  travelTypes,
  travelDates,
  travelRooms,
  travelDestination,
  submitButton,
  filters,
  className,
}: ISearchControlFormLayoutProps): React.ReactElement {
  const [t] = useTranslation('search');

  const { variant } = useSearchControlVariants();

  return (
    <SearchControlFormWrapper className={className}>
      <SearchControlFieldsWrapper
        withTabs={variant === ESearchControlVariants.WITH_TABS}
      >
        {travelTypes && variant === ESearchControlVariants.WITH_TABS ? (
          <div className="travelTypes">{travelTypes}</div>
        ) : null}
        <SearchControlFields className="fields">
          {variant === ESearchControlVariants.WITH_SWITCHER ? (
            <SearchControlTitle>
              {t('search:search.control.tooltip.title')}
            </SearchControlTitle>
          ) : null}
          {travelTypes && variant === ESearchControlVariants.WITH_SWITCHER ? (
            <div className="travelTypes">{travelTypes}</div>
          ) : null}
          <SearchControlFieldsInputs>
            {travelDestination ? (
              <div className="travelDestination">{travelDestination}</div>
            ) : null}
            {travelDates ? (
              <div className="travelDates">{travelDates}</div>
            ) : null}
            {travelRooms ? (
              <div className="travelRooms">{travelRooms}</div>
            ) : null}
          </SearchControlFieldsInputs>
          <SearchControlFieldsFooter>
            {filters ? <>{filters}</> : null}
            {submitButton ? <>{submitButton}</> : null}
          </SearchControlFieldsFooter>
        </SearchControlFields>
      </SearchControlFieldsWrapper>
    </SearchControlFormWrapper>
  );
}
