import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const SearchControlFormWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    minWidth: '250px',
    pt: 0,
    mt: '30px',
    '.absolute-dropdown': {
      position: 'absolute',
      p: '15px',
      border: '1px solid #999',
      borderRadius: 'default',
      boxShadow: '0 1px 7px rgb(0 0 0 / 50%)',
      top: '5px',
      '.apply-button': {
        mt: '15px',
        '.icon': {
          width: '14px',
          height: '14px',
        },
      },
    },
    '.autocomplete-dropdown': {
      zIndex: 100,
      p: 0,
      boxShadow: '0 1px 7px rgb(0 0 0 / 50%)',
      top: '0px',
      border: 'none',
      position: 'absolute',
      width: 'calc(100% - 31px)',
      '.autocomplete-suggestions': {
        flexDirection: 'column',
        maxHeight: '450px',
        cursor: 'default',
        borderRadius: '0 0 5px 5px',
        '.autocompleteFooterWrap': {
          display: 'flex',
          borderTop: '1px solid',
          borderColor: 'grayMiddle',
          mx: 3,
          pb: 3,
          pt: 2,
        },
        '.autocomplete-option': {
          mx: 3,
          py: 2,
          gap: 2,
          flexDirection: 'row-reverse',
          '.-icon': {
            ml: 'auto',
            mr: 0,
            '.icon': {
              width: '20px',
              height: '20px',
            },
          },
          '.-label': {
            fontSize: 1,
            color: 'darkGrey',
          },
        },
        button: {
          fontSize: 1,
        },
        li: {
          py: 0,
        },
        'li:hover': {
          backgroundColor: 'lightGreySixth',
        },
        ul: {
          overflowY: 'auto',
          position: 'relative',
          py: 3,
        },
        'ul + div': {
          pb: 2,
        },
      },
      '.autocomplete-suggestions:before': {
        content: "''",
        position: 'absolute',
        top: '42px',
        left: 3,
        width: '268px',
        height: '1px',
        display: 'block',
        backgroundColor: 'grayMiddle',
        zIndex: 10000,
      },
    },
    '.multiple-autocomplete': {
      '.base-input-wrapper': {
        input: {
          background: 'white',
          boxShadow: 'inset 1px 1px 3px rgb(0 0 0 / 35%)',
          position: 'relative',
        },
        '.icon': {
          right: '1px',
          width: '29px',
          height: '28px',
          background: 'linear-gradient(to bottom,#fff 0,#e7e7e7 100%)',
          padding: '6px',
          borderRadius: '0 4px 4px 0',
        },
      },
    },
  })
);

export const SearchControlFields = styled.div(
  sx2CssThemeFn({
    bg: 'white',
    p: '20px',
    border: '1px solid',
    borderTop: 'none',
    borderColor: 'borderGray',
    '.room-types, .board-types': {
      '.absolute-dropdown.search': {
        width: '330px',
      },
    },
    '.base-input-wrapper': {
      input: {
        fontSize: 0,
        fontWeight: 'bold',
      },
      '.input': {
        pl: '8px',
      },
      'input:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export const SearchControlFieldsWrapper = styled.div<{ withTabs: boolean }>(
  ({ withTabs, theme: { colors } }) =>
    sx2CssThemeFn({
      ...(withTabs
        ? {
            borderColor: 'borderGray',
            '.travel-type-switcher': {
              height: '54px',
              borderRadius: 0,
              borderColor: 'borderGray',
              borderTop: '1px solid',
              borderBottom: 'none',
              background: 'linear-gradient(to bottom, #fff 0, #e7e7e7 100%)',
              outline: `1px solid ${colors.borderGray}`,
              'input + label': {
                flex: '0 1 auto',
                height: '100%',
                py: '12px',
                px: 5,
                boxShadow: 'none',
                color: 'brandBlue',
                fontFamily: '"Helvetica Neue", sans-serif',
                fontSize: 2,
                borderRadius: 0,
                border: 'none',
                justifyContent: 'flex-start',
                borderLeft: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'borderGray',
                fontWeight: 400,
              },
              'input:checked + label': {
                background: 'white',
                whiteSpace: 'nowrap',
                color: 'secondary',
                borderBottom: 'none',
                mb: '1px',
              },
            },

            '.travel-type-switcher label:last-child': {
              flex: '1 1 auto',
              borderLeft: '1px solid',
              borderRight: '1px solid',
              borderColor: 'borderGray',
            },
          }
        : {
            borderTop: '1px solid',
            borderColor: 'borderGray',
            '.travel-type-switcher': {
              justifyContent: 'flex-start',
              width: 'fit-content',
              mb: 3,
              label: {
                px: 3,
                py: 2,
                justifyContent: 'center',
                whiteSpace: 'nowrap',
              },
            },
          }),
    })
);

export const SearchControlFieldsInputs = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    gap: 3,
    '.travelDestination, .travelDates, .travelRooms': {
      width: '100%',
    },
    '.travelDatesInput .input, .travelersInput .input, .travelDestinationInput .input':
      {
        height: '44px',
        background: 'none',
      },
    '.travelDatesInput .base-input-wrapper .icon, .travelersInput .base-input-wrapper .icon, .travelDestinationInput .base-input-wrapper .icon':
      {
        background: 'none',
        left: 3,
        height: '20px',
        width: '20px',
        p: 0,
        right: 'initial',
      },
    '.travelDatesInput .base-input-wrapper .input, .travelersInput .base-input-wrapper .input, .travelDestinationInput .base-input-wrapper .input':
      {
        pl: '46px',
        boxShadow: 'none',
        fontSize: 1,
        fontWeight: 400,
        pr: 3,
      },
    '.autocomplete-dropdown': {
      top: '20px',
      width: '100%',
      border: '1px solid',
      borderColor: 'borderGray',
    },
    '.autocomplete-dropdown .base-input-wrapper .input': {
      pl: '16px',
      borderRadius: '5px',
      border: 'none',
      height: '42px',
    },
    '.travelDatesInput:after, .travelersInput:after, .travelDestinationInput:after':
      {
        display: 'none',
      },
    '.travel-rooms-dropdown': {
      right: 0,
      '.dayPickerWrapper': {
        left: 'auto',
        right: 0,
        top: 'calc(100% + 5px)',
      },
    },
  })
);

export const SearchControlFieldsFooter = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    pt: 3,
  })
);

export const SearchControlTitle = styled.h2(
  sx2CssThemeFn({
    color: 'secondary',
    fontSize: '28px',
    fontWeight: 400,
    pb: 3,
  })
);
