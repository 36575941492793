import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Form, IFormApi } from '@hotelplan/components.common.forms';
import { useGSSContext } from '@hotelplan/libs.gss';
import { useSearchState } from '@hotelplan/libs.search-state';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { enhanceWithChooseAll } from 'components/domain/filters/Filters.mappers';
import { ISearchControlState } from 'components/domain/search-control/components/SearchControlForm/SearchControl.types';
import { useSearchControlVariants } from 'components/domain/search-control/hooks/useSearchControlVariants';
import { trackSearchSubmit } from 'components/domain/search-control/tracking/searchControlTracking';
import { useHomeDefaultSearchState } from 'components/domain/srl/useDefaultSearchState';
import useSRLFilterOptions from 'components/domain/srl/useSRLFilterOptions';
import {
  mapHomeFormValueToSearchState,
  mapSearchStateToHomeFormState,
} from './Home.mappers';
import { IHomeSearchState, THomeFormState } from './Home.types';
import HomeSearchControlForm from './HomeSearchControlForm';

const FormWrapper = styled(Form)<{ isSeparateBlock: boolean }>(
  ({ isSeparateBlock }) =>
    sx2CssThemeFn({
      position: ['static', isSeparateBlock ? 'static' : 'absolute'],
      top: 0,
      left: '24px',
      zIndex: 10,
      mb: ['20px', 0],
      '.submit-button': {
        width: isSeparateBlock ? 'auto' : '100%',
        textTransform: 'uppercase',
      },
    })
);

export default function HomeSearchControl(): React.ReactElement {
  const [t, { language }] = useTranslation('filters');
  const { state, setState } = useSearchState<IHomeSearchState>();
  const { setGSS, gss } = useGSSContext<ISearchControlState>();
  const { isSeparateBlock } = useSearchControlVariants();
  const [
    isDestinationSelectedFromOverlay,
    setIsDestinationSelectedFromOverlay,
  ] = useState(false);

  const formApiRef = useRef<IFormApi<THomeFormState>>(null);

  const { initialSearchControl } = useHomeDefaultSearchState(
    state.searchControl.travelType
  );
  const { options } = useSRLFilterOptions();

  function updateSearchControlState(nextState: THomeFormState) {
    const searchState = mapHomeFormValueToSearchState(nextState);

    trackSearchSubmit({
      t,
      searchState,
      language,
      initialSearchControl,
      filterOptions: enhanceWithChooseAll(
        options.departureAirports,
        t('departure.airport.select.all')
      ),
      defaultDepartureAirports: state.filters.departureAirports,
      isDestinationSelectedFromOverlay,
      gss,
    });
    setState(prevState => ({
      ...prevState,
      searchControl: searchState.searchControl,
      filters: searchState.filters,
    }));
    setGSS(initialSearchControl, searchState.searchControl);
  }

  function onChange(nextValues: THomeFormState) {
    formApiRef?.current?.setValues({
      ...nextValues,
    });
  }

  return (
    <FormWrapper
      formApiRef={formApiRef}
      initialValues={mapSearchStateToHomeFormState(state)}
      onChange={onChange}
      onSubmit={updateSearchControlState}
      isSeparateBlock={isSeparateBlock}
    >
      <HomeSearchControlForm
        onDestinationChange={value =>
          setIsDestinationSelectedFromOverlay(value)
        }
      />
    </FormWrapper>
  );
}
