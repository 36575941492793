import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { FeatureList } from 'config/pageConfig.constants';

export enum ESearchControlVariants {
  DEFAULT = 'version-a',
  WITH_TABS = 'version-b',
  WITH_SWITCHER = 'version-c',
}

interface ISearchControlVariants {
  variant: ESearchControlVariants;
  isSeparateBlock: boolean;
}

export const useSearchControlVariants = (): ISearchControlVariants => {
  const { getVariant } = useFeatureToggle();
  const { mobile } = useDeviceType();

  const variant = getVariant(FeatureList.SEARCH_CONTROL_VARIANT);

  return {
    variant: mobile
      ? ESearchControlVariants.DEFAULT
      : (variant?.name as ESearchControlVariants),
    isSeparateBlock:
      !mobile &&
      (variant?.name === ESearchControlVariants.WITH_TABS ||
        variant?.name === ESearchControlVariants.WITH_SWITCHER),
  };
};
