import { slotsConfig } from './Ads.config';

export const removeSlots = function () {
  const { googletag } = window;
  googletag.cmd.push(function () {
    googletag.destroySlots();
  });
};

export const defineSlots = (language: string) => {
  if (window) {
    const { googletag } = window;

    const render = () => {
      slotsConfig.forEach(({ id, name }) => {
        googletag
          .defineSlot(name, [300, 250], id)
          .addService(googletag.pubads());
      });
      googletag.pubads().enableSingleRequest();
      googletag.pubads().setTargeting('lang', language);
      googletag.enableServices();
      slotsConfig.forEach(({ id }) => {
        googletag.display(id);
      });
    };

    googletag?.cmd.push(function () {
      try {
        render();
      } catch (e) {
        removeSlots();
        render();
      }
    });
  }
};
