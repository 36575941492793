type SlotsConfig = { id: string; name: string }[];

export const slotsConfig: SlotsConfig = [
  {
    id: 'div-gpt-ad-1503413199893-0',
    name: '/21626869085/MF_Home/Below_Left',
  },
  {
    id: 'div-gpt-ad-1503413199893-1',
    name: '/21626869085/MF_Home/Below_Center',
  },
  {
    id: 'div-gpt-ad-1503413199893-2',
    name: '/21626869085/MF_Home/Below_Right',
  },
];

export const loadingTimeout = 3 * 1000;
