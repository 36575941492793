import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

interface IAdsSlotProps {
  id: string;
  timeout: number;
  hideSlot: (id: string) => void;
}

const SlotWrapper = styled.li<{ loaderVisible: boolean }>(
  sx2CssThemeFn(({ loaderVisible }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '312px',
    height: '260px',
    border: '1px solid',
    borderColor: '#999',

    '.loader': {
      display: loaderVisible ? 'block' : 'none',
    },
  }))
);

const AdSlot = styled.div(
  sx2CssThemeFn({
    width: '300px',
    height: '250px',

    '.loader': {
      position: 'absolute',
      width: '60px',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    },
  })
);

export default function AdsSlot({
  id,
  timeout,
  hideSlot,
}: IAdsSlotProps): React.ReactElement {
  const containerRef = useRef<HTMLLIElement>(null);
  const [loaderVisible, setLoaderVisible] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      if (!containerRef.current?.querySelector('iframe')) {
        setLoaderVisible(false);
        hideSlot(id);
      }
    }, timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, timeout, hideSlot]);

  return (
    <SlotWrapper ref={containerRef} loaderVisible={loaderVisible}>
      <AdSlot id={id}>
        <Image
          resized={[]}
          src="/images/spinner-button.svg"
          alt="LOADING"
          className="loader"
        />
      </AdSlot>
    </SlotWrapper>
  );
}
