import omit from 'lodash/omit';
import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useFormContext } from '@hotelplan/components.common.forms';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFirstRender } from '@hotelplan/libs.hooks-react';
import { useHomeDefaultSearchState } from 'components/domain/srl/useDefaultSearchState';
import { THomeFormState } from './Home.types';

// The hook's main purpose is to set the default values for the search control based on the user's travel type.
// It also checks if the form has been changed and, if not, sets the default values.
// The hook returns a stringified version of the form values.
export default function useHomeSearchControlDefaultValues() {
  const { mobile } = useDeviceType();

  const [isDefaultValueSet, setIsDefaultValuesSet] = useState<boolean>(null);
  const [formChanged, setFormChanged] = useState<boolean>(null);

  const { values, onChange } = useFormContext<THomeFormState>();
  const firstRender = useFirstRender();

  const travelType = values?.travelType;

  const { initialSearchControl, loading: defaultValuesLoading } =
    useHomeDefaultSearchState(travelType);

  useDeepCompareEffect(() => {
    if (!firstRender) {
      if (isDefaultValueSet) {
        setFormChanged(false);
        setIsDefaultValuesSet(false);
        return;
      }

      setFormChanged(true);
    }
  }, [omit(values, 'travelType')]);

  useEffect(
    function useDefaultValues() {
      if (!firstRender) {
        if (!defaultValuesLoading && initialSearchControl && !formChanged) {
          onChange({
            ...values,
            ...initialSearchControl,
          });
          setIsDefaultValuesSet(true);
        }
      }
    },
    [travelType, formChanged]
  );

  return !mobile ? JSON.stringify(values) : '';
}
