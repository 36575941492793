import compose from 'lodash/fp/compose';
import React from 'react';
import styled from 'styled-components';
import { LoginErrorNotification } from '@hotelplan/components.common.auth';
import { PageType } from '@hotelplan/graphql.types';
import {
  mapGlobalSearchStateToFormState,
  mapGlobalSearchStateToSearchControlCriteriaInput,
} from '@hotelplan/libs.gss';
import { gssUnitRef } from '@hotelplan/libs.gss-api';
import { createLanguageURLsProps } from '@hotelplan/libs.language-urls';
import { createOpenGraphProp } from '@hotelplan/libs.open-graph';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { routeConfigUnitRef } from '@hotelplan/libs.route-config-api';
import { withSearchStatePageContext } from '@hotelplan/libs.search-state';
import { ServerSideUnit, ServerSideUnitRef } from '@hotelplan/libs.ssp-units';
import { Container } from 'components/core/container';
import GoogleDCPublisherAds from 'components/domain/ads/Ads';
import { mapSrlFilterValuesToSrlFilterCriteriaInput } from 'components/domain/filters/Filters.mappers';
import { IHomeSearchState } from 'components/domain/home/Home.types';
import HomeSearchControl from 'components/domain/home/HomeSearchControl';
import LastSeenHotelsComponent from 'components/domain/last-seen-hotels/LastSeenHotelsComponent';
import { useLastSeenHotels } from 'components/domain/last-seen-hotels/useLastSeenHotels';
import { mapSearchControlToCriteria } from 'components/domain/search-control/components/SearchControlForm/SearchControl.mappers';
import { StructuredDataOrganization } from 'components/domain/structured-org';
import DynamicComponents from 'components/dynamic-components/DynamicComponents';
import { createPageServerFn, lastSeenHotelsUnit } from 'config';
import { homeSearchStateUnit } from 'config/home/homeSearchStateUnit';
import {
  DynamicComponentFragment,
  GetComponentsDocument,
  GetComponentsQuery,
  GetComponentsQueryVariables,
} from 'graphql/components/GetComponents.generated';
import {
  GetHomeMetaDataDocument,
  GetHomeMetaDataQuery,
  GetHomeMetaDataQueryVariables,
} from 'graphql/home/GetHomeMetaData.generated';
import {
  GetHomeStaticContentDocument,
  GetHomeStaticContentQuery,
  GetHomeStaticContentQueryVariables,
} from 'graphql/home/GetHomeStaticContent.generated';
import {
  GetSrlToSrlUrlDocument,
  GetSrlToSrlUrlQuery,
  GetSrlToSrlUrlQueryVariables,
} from 'graphql/srl/GetSrlToSrlUrl.generated';

const ContainerWrapper = styled(Container)({
  position: 'relative',
});

interface IHomePageProps {
  components: Array<DynamicComponentFragment>;
}

function HomePage({ components }: IHomePageProps): React.ReactElement {
  const lastSeenHotels = useLastSeenHotels();

  return (
    <ContainerWrapper>
      <HomeSearchControl />
      <DynamicComponents components={components} />
      {lastSeenHotels?.length ? (
        <LastSeenHotelsComponent lastSeenHotels={lastSeenHotels} />
      ) : null}
      <GoogleDCPublisherAds />
      <StructuredDataOrganization />
      <LoginErrorNotification />
    </ContainerWrapper>
  );
}

const preloadHomeDataUnit = ServerSideUnit.depsOf([
  apolloReqCtxUnitRef,
  routeConfigUnitRef,
  gssUnitRef,
])(async function (ctx, { queryCtx }, { pathname, searchParams }, gssPair) {
  const path = searchParams.toString()
    ? `${pathname}?${searchParams}`
    : pathname;

  const [
    { data: metaData = null },
    { data: staticData },
    { data: dynamicComponents },
  ] = await Promise.all([
    queryCtx<GetHomeMetaDataQuery, GetHomeMetaDataQueryVariables>({
      query: GetHomeMetaDataDocument,
    }),
    queryCtx<GetHomeStaticContentQuery, GetHomeStaticContentQueryVariables>({
      query: GetHomeStaticContentDocument,
    }),
    queryCtx<GetComponentsQuery, GetComponentsQueryVariables>({
      query: GetComponentsDocument,
      variables: {
        input: {
          pageType: PageType.Home,
          currentUri: path,
        },
        gss: mapGlobalSearchStateToSearchControlCriteriaInput(
          mapGlobalSearchStateToFormState(gssPair?.gss)
        ),
      },
    }),
  ]);

  const shareDefaultImage =
    staticData?.home.staticContent.sharingOverlayDefaultImage.resized[0].url;

  return {
    props: {
      meta: metaData.home.meta,
      ...createOpenGraphProp({
        image: shareDefaultImage,
      }),
      components: dynamicComponents?.components,
      pathname: path,
    },
  };
}).withRef(new ServerSideUnitRef(`preload-home`));

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.Home,
    pageEventType: `home`,
    namespaceRequired: ['results'],
    initialProps: createLanguageURLsProps({
      de: { uri: `/`, targetPageType: PageType.Home },
      fr: { uri: `/`, targetPageType: PageType.Home },
    }),
  },
  [preloadHomeDataUnit, homeSearchStateUnit, lastSeenHotelsUnit]
);

export default compose(
  withSearchStatePageContext<
    IHomeSearchState,
    GetSrlToSrlUrlQuery,
    GetSrlToSrlUrlQueryVariables
  >({
    urlQuery: GetSrlToSrlUrlDocument,
    mapPageStateToUrlQueryVariables: (
      pageState: IHomeSearchState
    ): GetSrlToSrlUrlQueryVariables => {
      return {
        searchControl: mapSearchControlToCriteria(pageState.searchControl),
        filters: mapSrlFilterValuesToSrlFilterCriteriaInput(pageState.filters),
      };
    },
    mapUrlQueryResultToInternalLink: ({ srl }) => srl.link,
    withHistorySaved: true,
  })
)(HomePage);
