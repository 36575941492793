import { IHomeSearchState, THomeFormState } from './Home.types';

export function mapHomeFormValueToSearchState(
  values: THomeFormState
): IHomeSearchState {
  const {
    travelRooms,
    travelType,
    travelDestination,
    travelDates,
    departureAirports,
  } = values;

  return {
    searchControl: {
      travelDates,
      travelRooms,
      travelType,
      travelDestination,
    },
    filters: { departureAirports },
  };
}

export function mapSearchStateToHomeFormState(
  searchState: IHomeSearchState
): THomeFormState {
  const { searchControl, filters } = searchState;

  return {
    ...searchControl,
    departureAirports: filters.departureAirports,
  };
}
