import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { slotsConfig, loadingTimeout } from './Ads.config';
import AdsLoader from './AdsLoader';
import AdsSlot from './AdsSlot';

const AdsWrapper = styled.ul(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: [null, 'repeat(3,1fr)'],
    gridAutoFlow: [null, 'row'],
    gap: ['25px 15px', '35px 18px'],
    justifyContent: ['center', null],
  })
);

export default function Ads(): React.ReactElement {
  const [visibleIds, setVisibleIds] = useState<string[]>(
    slotsConfig.map(slot => slot.id)
  );

  const hideSlot = useCallback(
    (idToHide: string) => {
      setVisibleIds(prevIds => prevIds.filter(id => id !== idToHide));
    },
    [setVisibleIds]
  );

  return visibleIds.length ? (
    <AdsWrapper>
      <AdsLoader />
      {slotsConfig.map(({ id }, index) => (
        <AdsSlot
          id={id}
          key={`ads-slot-${index}`}
          timeout={loadingTimeout}
          hideSlot={hideSlot}
        />
      ))}
    </AdsWrapper>
  ) : null;
}
