import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import { useSearchFilters } from 'components/domain/filters/useSearchFilters';
import SearchControlFieldLabel from 'components/domain/search-control/components/SearchControlFieldLabel/SearchControlFieldLabel';
import SearchControlFormLayout from 'components/domain/search-control/components/SearchControlFormLayout/SearchControlFormLayout';
import SearchControlFormLayoutSeparateBlock from 'components/domain/search-control/components/SearchControlFormLayout/SearchControlFormLayoutSeparateBlock';
import { useSearchControlVariants } from 'components/domain/search-control/hooks/useSearchControlVariants';
import { SRLAlternativeSearchModal } from 'components/domain/srl/SRLEmptyResults';
import useSRLFilterOptions from 'components/domain/srl/useSRLFilterOptions';
import TravelDatesContainer from 'components/domain/travel-dates/TravelDatesContainer';
import BaseTravelDestinationField from 'components/domain/travel-destination/BaseTravelDestinationField';
import TravelRoomsContainer from 'components/domain/travel-rooms/TravelRoomsContainer';
import TravelTypeField from 'components/domain/travel-type/TravelTypeField';
import {
  GetHomeDestinationAutocompleteDocument,
  GetHomeDestinationAutocompleteQuery,
  GetHomeDestinationAutocompleteQueryVariables,
} from 'graphql/home/GetHomeDestinationAutocomplete.generated';
import useHomeSearchControlDefaultValues from './useHomeSearchControlDefaultValues';
import { useSearchTotalResults } from './useSearchTotalResults';

const SubmitButton = styled(Button).attrs({
  icon: {
    name: 'chevron-long-right',
    size: 'sm',
  },
})<{ isSeparateBlock: boolean }>(({ theme, isSeparateBlock }) =>
  sx2CssThemeFn({
    ...theme.buttons.btnWithIcon,
    ...(isSeparateBlock
      ? {
          alignSelf: 'flex-end',
          py: '10px',
          px: 3,
        }
      : {}),
  })
);

const SearchControlFormLayoutWrapper = styled(SearchControlFormLayout)(
  sx2CssThemeFn({
    boxShadow: '3px 3px 5px rgb(0 0 0 / 40%)',
  })
);

const SubmitButtonSkeleton = ({
  isSeparateBlock,
}: {
  isSeparateBlock: boolean;
}) => {
  const { w: width, h: height } = {
    w: isSeparateBlock ? 32 : 40,
    h: isSeparateBlock ? 18 : 20,
  };

  return (
    <PlaceholderLoader
      width={width}
      height={height}
      uid="buttonSkeleton"
      style={{ height: height, width: width, display: 'block' }}
    >
      <rect width={isSeparateBlock ? 32 : 40} height="18" rx="8" />
    </PlaceholderLoader>
  );
};

export default function HomeSearchControlForm({
  onDestinationChange,
}: {
  onDestinationChange: (value: boolean) => void;
}): React.ReactElement {
  const [t] = useTranslation('common');

  const {
    totalResults,
    totalResultsLoading,
    showNoResultModal,
    switchTravelType,
    altTravelType,
  } = useSearchTotalResults();
  const { options, optionCaptions, loading } = useSRLFilterOptions();
  const filters = useSearchFilters();
  const { isSeparateBlock } = useSearchControlVariants();

  const SearchControlFormLayoutWrapperVariant = isSeparateBlock
    ? SearchControlFormLayoutSeparateBlock
    : SearchControlFormLayoutWrapper;

  const updateKey = useHomeSearchControlDefaultValues();

  if (loading || !filters) return null;

  return (
    <>
      <SearchControlFormLayoutWrapperVariant
        key={updateKey}
        travelTypes={
          <TravelTypeField labelFirstSeparator={isSeparateBlock && '&'} />
        }
        travelDestination={
          <BaseTravelDestinationField<
            GetHomeDestinationAutocompleteQuery,
            GetHomeDestinationAutocompleteQueryVariables
          >
            queryHandlers={{
              queryDocument: GetHomeDestinationAutocompleteDocument,
              dataMapper: data =>
                data
                  ? {
                      destinations: data.home.autocomplete.destinations,
                      page: data.home.autocomplete.page,
                    }
                  : undefined,
              variablesMapper: variables => ({ ...variables }),
            }}
            label={
              isSeparateBlock && (
                <SearchControlFieldLabel htmlFor="travelDestination">
                  {t('search:travelDestination.label')}
                </SearchControlFieldLabel>
              )
            }
            isSeparateBlock={isSeparateBlock}
            onDestinationChange={onDestinationChange}
          />
        }
        travelDates={
          <TravelDatesContainer
            label={
              isSeparateBlock && (
                <SearchControlFieldLabel htmlFor="travelDates">
                  {t('search:travelDates.label')}
                </SearchControlFieldLabel>
              )
            }
          />
        }
        travelRooms={
          <TravelRoomsContainer
            label={
              isSeparateBlock && (
                <SearchControlFieldLabel htmlFor="travelers">
                  {t('search:travelRooms.label')}
                </SearchControlFieldLabel>
              )
            }
          />
        }
        filters={filters.departureAirports.render({
          filterCaption: optionCaptions.departureAirports,
          filterOptions: options.departureAirports,
          secondVariant: isSeparateBlock,
        })}
        submitButton={
          <SubmitButton
            type="submit"
            className={'submit-button'}
            isSeparateBlock={isSeparateBlock}
          >
            {totalResultsLoading ? (
              <SubmitButtonSkeleton isSeparateBlock={isSeparateBlock} />
            ) : (
              <span>{totalResults}</span>
            )}{' '}
            {t('children.offer.cta')}
          </SubmitButton>
        }
        customConfig={{
          showMobileTitle: true,
          hideActionButton: true,
        }}
      />
      {showNoResultModal ? (
        <SRLAlternativeSearchModal
          alternativeTravelType={altTravelType}
          customTransition={switchTravelType}
          searchButtonTranslation={'common:no'}
        />
      ) : null}
    </>
  );
}
